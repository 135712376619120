import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  textContainer: {
    marginLeft: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
  },
  heading: {
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(4),
    alignSelf: 'center',
  },
  img: {
    width: '250px',
    height: '500px',
    objectFit: 'cover',
    borderRadius: '500px 0 0 500px',
    marginLeft: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      borderRadius: '300px',
      width: '500px',
      height: '500px',
      margin: 'auto',
      marginBottom: theme.spacing(8),
      marginTop: theme.spacing(8),
    },
  },
}))

export default useStyles
