import React from 'react'
import styled from '@emotion/styled'
import { addTypographyClasses } from 'utils/functions'
import Layout from 'components/layout/layout'
import Typography from '@material-ui/core/Typography'
import useStyles from './event-styles'
import pdf from '../assets/NHDSA-Exceptional-Educator-Award-22-23.pdf'

export default function Event({ pageContext }) {
  var { event } = pageContext
  var classes = useStyles()

  var Description = styled.div`
    a {
      color: #fff;
      text-decoration: underline;
    }
  `

  return (
    <Layout title={event.title}>
      <div className={classes.root}>
        <div className={classes.textContainer}>
          <Typography variant="h2" component="h1" className={classes.heading}>
            {event.title}
          </Typography>
          <Description
            dangerouslySetInnerHTML={{
              __html: addTypographyClasses(event.description.html),
            }}
          />
          {/* {(event.slug === 'santa' || event.slug === 'santa-virtual') && (
            <Button
              size="large"
              className={classes.button}
              variant="contained"
              href={
                event.slug === 'santa'
                  ? 'https://www.signupgenius.com/go/60B094AA8AC2AABF49-visit'
                  : 'https://www.signupgenius.com/go/60B094AA8AC2AABF49-virtual'
              }
            >
              Sign Up!
            </Button>
          )} 
          {event.slug === 'educator-award' && (
            <object
              data={pdf}
              type="application/pdf"
              width="100%"
              height="100%"
              style={{
                marginTop: '32px',
              }}
            >
              2022-2023 NHDSA Exceptional Educator Award form
            </object>
          )} */}
        </div>
        <img
          src={event.img.image.url}
          alt={event.img.alt}
          className={classes.img}
        />
      </div>
    </Layout>
  )
}
